import React, { useState } from 'react';

import './input.css';

const TextArea = ({
  accept,
  containerClass = '',
  disabled = false,
  error,
  id,
  inputClass = '',
  label,
  name,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  maxLength,
  requeried = false,
  resize,
  type = "text",
  value,
}) => {
  const resizeClass = resize ? '' : 'text-area-no-resize';

  function handleOnChange(event) {
    if (onChange) {
      onChange(event);
    }
  }

  return (
    <div className={`custom-input flex-col p-h-8 ${containerClass}`}>
      <label htmlFor={id} className="input-from">
        <span className="text-primary-color f-bold">
          {label} {requeried ? ' *' : ''}
        </span>

        <textarea
          accept={accept}
          className={`${inputClass} ${resizeClass}`}
          disabled={disabled}
          id={id}
          name={name || id}
          onChange={handleOnChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder || ''}
          required={requeried}
          type={type}
          maxLength={maxLength}
          value={value}
        />
      </label>

      <span>{error}</span>
    </div>
  );
};

export { TextArea }
