import React, { useState } from 'react';

import { ImgBannerContainer } from '../components/common/img';
import { Layout } from '../components/layout/layout';
import { Input, TextArea } from '../components/common/form';
import { googleMapKey } from '../data/constant';

import { apiPost } from '../utils';

import ImgBanner from '../images/banner-contacto.png';
import './css/contactPage.css';

const ContactPage = ({ pageContext }) => {
  const { club, menus } = pageContext;
  const { facebookLink, twitterLink, instagramLink, contact = {} } = club;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState('');
  const [errorEmail, setEmailError] = useState('');

  const formDisabed = isLoading || sendEmail;

  async function onSubmit(event) {
    event.preventDefault();

    if (formDisabed || !name || !email || !lastName || phone.length < 10 || !message) {
      return;
    }

    const body = {
      name,
      lastName,
      email,
      phone,
      message,
      clubName: club.name,
    };

    setEmailError('');
    setSendEmail('');
    setLoading(true);
    const res = await apiPost({ body, url: `correo/contactToMe/${club.id}` });
    setLoading(false);

    if (res.isValid) {
      setSendEmail('¡Correo enviado!');
    } else {
      setEmailError('¡Ups..! Ocurrio un error al enviar el correo');
    }
  }

  const updateState = (callback = () => { }) => ({ target }) => {
    const { value } = target;

    callback(value);
  };

  return (
    <Layout
      facebookLink={facebookLink}
      instagramLink={instagramLink}
      logo={club.logo}
      menu={menus}
      rootPath={club.route ? `../${club.route}` : '/'}
      twitterLink={twitterLink}
      whiteTheme
    >
      <>
        <ImgBannerContainer img={ImgBanner}>
          <>
            <h1 className="text-center p-b-16">Contacto</h1>

            <h2 className="text-white text-center font-light">Home//Contacto</h2>
          </>
        </ImgBannerContainer>

        <div className="contact-background">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-sm-12 col-md-3 card contact-card">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" /></svg>

                <h3 className="font-bold">Dirección</h3>

                <a href={`https://maps.google.com/?q=${contact.location}`}>
                  <span>{contact.location}</span>
                </a>
              </div>

              <div className="col-sm-12 col-md-3 card contact-card">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z" /></svg>

                <h3 className="font-bold">Correos</h3>

                {contact.email1 && (
                  <a href={`mailto:${contact.email1}`}>
                    <span>{contact.email1}</span>
                  </a>
                )}

                {contact.email2 && (
                  <a href={`mailto:${contact.email2}`}>
                    <span>{contact.email2}</span>
                  </a>
                )}

              </div>

              <div className="col-sm-12 col-md-3 card contact-card">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z" /></svg>

                <h3 className="font-bold">Télefonos</h3>

                {contact.email1 && (
                  <a href={`tel:${contact.phone1}`}>
                    <span>{contact.phone1}</span>
                  </a>
                )}

                {contact.email2 && (
                  <a href={`tel:${contact.phone2}`}>
                    <span>{contact.phone2}</span>
                  </a>
                )}
              </div>
            </div>

            <div className="row contact-div">
              <iframe
                allowFullScreen
                title="Locación"
                // height={450}
                // width={600}
                className="col-sm-12 col-md-6 contact-map"
                loading="lazy"
                style={{ border: 0, minHeight: 280 }}
                src={`https://www.google.com/maps/embed/v1/place?key=${googleMapKey}&q=${encodeURI(contact.location)}`}
              />

              <div className="col-sm-12 col-md-6">
                <div className="p-h-9">
                  <span className="font-bold contact-div-title">¿Dudas o sugierencias?</span>

                  <p className="font-light">
                    Tienes alguna duda? Estamos para
                    servirte a través de este medio, o si
                    prefieres, a través de los números de
                    contacto
                  </p>
                </div>

                <form className="contact-from" onSubmit={onSubmit}>
                  <Input disabled={formDisabed} id="name" onChange={updateState(setName)} requeried placeholder="Nombre(s)" />

                  <Input disabled={formDisabed} id="last-name" onChange={updateState(setLLastName)} requeried placeholder="Apellido(s)" />

                  <Input disabled={formDisabed} id="email" onChange={updateState(setEmail)} type="email" requeried placeholder="Correo electrónico" />

                  <Input disabled={formDisabed} id="phone" onChange={updateState(setPhone)} maxLength={10} type="number" requeried placeholder="Télefono móvil" />

                  <TextArea disabled={formDisabed} id="message" onChange={updateState(setMessage)} placeholder="Mensaje" />

                  <div className="p-h-9 contact-button-div">
                    <div className="text-center">
                      <span className="email-error">{errorEmail}</span>
                    </div>

                    <button className="btn btn-blue text-white" disabled={formDisabed} type="submit">
                      {sendEmail || 'Enviar'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default ContactPage;
