import React from 'react';

import './input.css';

const Input = ({
  accept,
  containerClass = '',
  disabled = false,
  error,
  id,
  inputClass = '',
  label,
  name,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  maxLength,
  requeried = false,
  type = "text",
  value,
}) => {
  function handleOnChange(event) {
    if (type === "number" && maxLength) {
      const { value } = event.target;

      if (value.length > maxLength) return;
    }

    if (onChange) {
      onChange(event);
    }
  }

  return (
    <div className={`custom-input flex-col p-h-8 ${containerClass}`}>
      <label htmlFor={id} className="input-from">
        {label && (
          <span className="text-primary-color f-bold">
            {label} {requeried ? ' *' : ''}
          </span>
        )}

        <input
          accept={accept}
          className={inputClass}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          name={name || id}
          onChange={handleOnChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder || ''}
          required={requeried}
          type={type}
          value={value}
        />
      </label>

      <span className="input-error">{error}</span>
    </div>
  );
};

export { Input }
